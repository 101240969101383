import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useTranslation, useI18next } from "gatsby-plugin-react-i18next"
import sergiler1 from "../assets/img/sergiler1.jpg"
import sergiler2 from "../assets/img/sergiler2.png"
import sergiler3 from "../assets/img/sergiler3.png"
import sergiler4 from "../assets/img/sergiler4.png"
import sergiler5 from "../assets/img/sergiler5.png"

export default function Sergiler({ data }) {
  const { t } = useTranslation()
  const { language } = useI18next()

  const sergiler = data?.data?.edges?.[0].node?.frontmatter?.sergi?.sort(
    (a, b) => {
      if (a.sergi_yili > b.sergi_yili) return 1
      if (a.sergi_yili < b.sergi_yili) return -1
      return 0
    }
  )
  return (
    <Layout>
      <SEO title={t("sergiler")} />
      <div className="sergiler-container">
        <div className="page-title">
          <div className="top">
            <div className="left">
              <img src={sergiler1} alt="" />
            </div>
            <div className="right">
              <img src={sergiler2} alt="" />
              <img src={sergiler3} alt="" />
            </div>
          </div>
          <div className="bottom">
            <img src={sergiler4} alt="" />
            <img src={sergiler5} alt="" />
          </div>
        </div>
        <img src={sergiler1} alt="" className="sergiler-mobil-img" />
        <div className="sergiler-content">
          {sergiler.map((sergi, index) => {
            return (
              <div className="sergi" key={index}>
                <div className="sergi-year">
                  {`${sergi.sergi_yili?.toString().substr(0, 2)}\n
                  ${sergi.sergi_yili?.toString().substr(2, 2)}`}
                </div>
                <div className="sergi-details">
                  <div className="sergi-name">
                    {language === "en" ? sergi.sergi_adi_en : sergi.sergi_adi}
                  </div>
                  <div className="sergi-place">
                    {language === "en"
                      ? sergi.sergi_lokasyon_en
                      : sergi.sergi_lokasyon}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
        <img src={sergiler5} alt="" className="sergiler-mobil-img" />
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Sergiler {
    data: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "sergi" } } }
    ) {
      edges {
        node {
          frontmatter {
            sergi {
              sergi_adi
              sergi_adi_en
              sergi_lokasyon
              sergi_lokasyon_en
              sergi_yili
            }
          }
        }
      }
    }
  }
`
